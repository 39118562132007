import { RadioButton } from '../radioButton/RadioButton';
import './SelectOption.scss';

export type SelectOptionProps = {
    title: string;
    subtitle?: string;
    selected: boolean;
    onSelect: () => void;
};

export const SelectOption = ({
    title,
    subtitle,
    selected,
    onSelect,
}: SelectOptionProps) => {
    return (
        <div
            className={`select-option ${subtitle && 'with-subtitle'}`}
            onClick={onSelect}>
            <div className="title">{title}</div>
            {subtitle && <div className="subtitle">{subtitle}</div>}
            <div className="radio-button">
                <RadioButton active={selected} />
            </div>
        </div>
    );
};
