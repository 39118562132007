import { Step } from '../../../shared/components/stepper/Stepper';

export const steps: Step[] = [
    {
        title: 'Выбор профессии',
        subtitle: 'Выберите профессию, по которой хотите пройти собеседование',
    },
    {
        title: 'Выбор сложности',
        subtitle: 'Выберите сложность собеседования',
    },
];
