// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-link {
  display: flex;
  flex-direction: row;
  gap: 6px;
  color: var(--light-grey) !important;
  font: var(--font-reg-14);
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  .header-link .header-link-title {
    display: none;
  }
}
.header-link:hover, .header-link.active {
  color: var(--white) !important;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/header/HeaderLink.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mCAAA;EACA,wBAAA;EACA,mBAAA;EACA,eAAA;EACA,qBAAA;AACJ;AACI;EACI;IACI,aAAA;EACV;AACF;AAEI;EAEI,8BAAA;AADR","sourcesContent":[".header-link {\n    display: flex;\n    flex-direction: row;\n    gap: 6px;\n    color: var(--light-grey) !important;\n    font: var(--font-reg-14);\n    align-items: center;\n    cursor: pointer;\n    text-decoration: none;\n\n    @media screen and (max-width: 500px) {\n        .header-link-title {\n            display: none;\n        }\n    }\n    \n    &:hover,\n    &.active {\n        color: var(--white) !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
