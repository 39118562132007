// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-option {
  display: grid;
  grid-template: "title radio-button" "title radio-button";
  padding: 6px 16px;
  height: 44px;
  max-height: 44px;
  gap: 2px;
  align-items: center;
  box-sizing: border-box;
}
.select-option.with-subtitle {
  grid-template: "title radio-button" "subtitle radio-button";
}

.title {
  width: fit-content;
  height: fit-content;
  grid-area: title;
  color: var(--light-grey);
  font: var(--font-reg-14);
}

.subtitle {
  width: fit-content;
  height: fit-content;
  grid-area: subtitle;
  color: var(--grey);
  font: var(--font-reg-12);
}

.radio-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-area: radio-button;
  align-items: center;
  width: 100%;
  height: 100%;
}

.select-option:hover .title {
  color: var(--white);
}
.select-option:hover .subtitle {
  color: var(--light-grey);
}`, "",{"version":3,"sources":["webpack://./src/shared/components/selectOption/SelectOption.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,wDAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,QAAA;EACA,mBAAA;EACA,sBAAA;AACJ;AACI;EACI,2DAAA;AACR;;AAGA;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,wBAAA;EACA,wBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,wBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAAF;;AAIE;EACE,mBAAA;AADJ;AAIE;EACE,wBAAA;AAFJ","sourcesContent":[".select-option {\n    display: grid;\n    grid-template: 'title radio-button' 'title radio-button';\n    padding: 6px 16px;\n    height: 44px;\n    max-height: 44px;\n    gap: 2px;\n    align-items: center;\n    box-sizing: border-box;\n\n    &.with-subtitle {\n        grid-template: 'title radio-button' 'subtitle radio-button';\n    }\n}\n\n.title {\n  width: fit-content;\n  height: fit-content;\n  grid-area: title;\n  color: var(--light-grey);\n  font: var(--font-reg-14);\n}\n\n.subtitle {\n  width: fit-content;\n  height: fit-content;\n  grid-area: subtitle;\n  color: var(--grey);\n  font: var(--font-reg-12);\n}\n\n.radio-button {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  grid-area: radio-button;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n\n.select-option:hover {\n  .title {\n    color: var(--white);\n  }\n  \n  .subtitle {\n    color: var(--light-grey);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
