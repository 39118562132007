import React, { HTMLAttributes } from 'react';
import { InterviewModel } from '../core/models';
import { availableLevels } from '../../../app/availableLevelsConstant';
import './InterviewCard.scss';

export type InterviewCardOwnProps = {
    interview: InterviewModel;
};

export type InterviewCardProps = HTMLAttributes<EventTarget> &
    InterviewCardOwnProps;

export const InterviewCardComponent: React.FC<InterviewCardProps> = ({
    interview,
    className,
    ...props
}) => {
    return (
        <div {...props} className={'interview-card ' + className}>
            <div className="title">
                {interview.profession_name}, {interview.category_name}
            </div>
            <div className="pass-time">Пройдено {interview.pass_datetime}</div>
            <div className="property property-level">
                <div className="label">Уровень:</div>
                <div className="value">
                    {
                        availableLevels.find(
                            (i) => i.level === interview.difficulty
                        )?.title
                    }
                </div>
            </div>
            <div className="property property-score">
                <div className="label">Баллы:</div>
                <div className="value">{interview.score}/100</div>
            </div>
        </div>
    );
};
