import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllInterviews, getInterviewsWithFilters, getSpecializations } from './api';
import { InterviewsFilters } from './types';

export const getSpecializationsThunk = createAsyncThunk(
    'interviewsCatalog/getSpecializations',
    async () => {
        const res = await getSpecializations();
        return res.data;
    }
);

export const getAllInterviewsThunk = createAsyncThunk(
    'interviewsCatalog/getAllInterviews',
    async () => {
        const res = await getAllInterviews();
        return res.data;
    }
);

export const getInterviewsWithFiltersThunk = createAsyncThunk(
    'interviewsCatalog/getInterviewsWithFilters',
    async (filters: InterviewsFilters) => {
        const res = await getInterviewsWithFilters(filters);
        return res.data;
    }
);
