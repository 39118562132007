import { ApiStatusType } from '../../../app/api/apiStatusType';
import { RootState } from '../../../app/store';

export const selectIsAuth = (state: RootState) => !!state.auth.user;
export const selectCSRFToken = (state: RootState) => !!state.auth.CSRFToken;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentUserName = (state: RootState) => state.auth.user?.username;
export const selectRecruiterPermission = (state: RootState) => state.auth.user?.is_recruiter;
export const selectCurrentAuthStatus = (state: RootState) => state.auth.status;
export const selectCurrentAuthInProgress = (state: RootState) => state.auth.isAuthInProgress;
export const selectCurrentAuthStatusMessage = (state: RootState) =>
    state.auth.message;
