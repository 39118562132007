// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interview-preparation {
  display: flex;
  flex-direction: column;
  padding: 32px 16px 0;
}

.interview-preparation-content {
  padding-top: 32px;
  width: 100%;
}

.interview-preparation-button {
  width: 300px;
}
@media screen and (max-width: 700px) {
  .interview-preparation-button {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/features/interviewPreparation/InterviewPreparationPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,WAAA;AACJ;;AAEA;EACI,YAAA;AACJ;AAAI;EAFJ;IAGQ,WAAA;EAGN;AACF","sourcesContent":[".interview-preparation {\n    display: flex;\n    flex-direction: column;\n    padding: 32px 16px 0;\n}\n\n.interview-preparation-content {\n    padding-top: 32px;\n    width: 100%;\n}\n\n.interview-preparation-button {\n    width: 300px;\n    @media screen and (max-width: 700px) {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
