// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 14px 16px;
  border-bottom: var(--middle-grey) solid 1px;
  color: var(--light-grey);
  font: var(--font-reg-14);
  background-color: var(--black);
  width: 100%;
  box-sizing: border-box;
}
.input-container .input-container-inside {
  display: flex;
  width: 100%;
  position: relative;
}
.input-container ::placeholder,
.input-container ::-webkit-input-placeholder {
  color: var(--light-grey);
  font: var(--font-reg-14);
}
.input-container .input {
  color: var(--white);
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background: transparent;
  font: var(--font-reg-14);
  min-width: 160px;
}
.input-container.hover {
  border-bottom: var(--grey) solid 1px;
}
.input-container.focus {
  color: var(--white);
  border-bottom: var(--positive) solid 1px;
}
.input-container.hover.focus {
  color: var(--white);
  border-bottom: var(--positive-hover) solid 1px;
}
.input-container .input-left-icon,
.input-container .input-right-icon {
  display: flex;
  min-width: 16px;
  width: 16px;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/input/Input.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;EACA,2CAAA;EACA,wBAAA;EACA,wBAAA;EACA,8BAAA;EACA,WAAA;EACA,sBAAA;AACJ;AACI;EACI,aAAA;EACA,WAAA;EACA,kBAAA;AACR;AAEI;;EAEI,wBAAA;EACA,wBAAA;AAAR;AAGI;EACI,mBAAA;EACA,YAAA;EACA,aAAA;EACA,UAAA;EACA,SAAA;EACA,uBAAA;EACA,wBAAA;EACA,gBAAA;AADR;AAII;EACI,oCAAA;AAFR;AAKI;EACI,mBAAA;EACA,wCAAA;AAHR;AAMI;EACI,mBAAA;EACA,8CAAA;AAJR;AAOI;;EAEI,aAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;AALR","sourcesContent":[".input-container {\n    position: relative;\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n    padding: 14px 16px;\n    border-bottom: var(--middle-grey) solid 1px;\n    color: var(--light-grey);\n    font: var(--font-reg-14);\n    background-color: var(--black);\n    width: 100%;\n    box-sizing: border-box;\n\n    .input-container-inside {\n        display: flex;\n        width: 100%;\n        position: relative;\n    }\n\n    ::placeholder,\n    ::-webkit-input-placeholder {\n        color: var(--light-grey);\n        font: var(--font-reg-14);\n    }\n\n    .input {\n        color: var(--white);\n        border: none;\n        outline: none;\n        padding: 0;\n        margin: 0;\n        background: transparent;\n        font: var(--font-reg-14);\n        min-width: 160px;\n    }\n\n    &.hover {\n        border-bottom: var(--grey) solid 1px;\n    }\n\n    &.focus {\n        color: var(--white);\n        border-bottom: var(--positive) solid 1px;\n    }\n\n    &.hover.focus {\n        color: var(--white);\n        border-bottom: var(--positive-hover) solid 1px;\n    }\n\n    .input-left-icon,\n    .input-right-icon {\n        display: flex;\n        min-width: 16px;\n        width: 16px;\n        height: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
