// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  height: 44px;
  gap: 8px;
  color: var(--light-grey);
  background-color: var(--dark-grey);
}
.menu-item:hover {
  color: var(--white);
  background-color: var(--hover-grey);
}
.menu-item .title {
  font: var(--font-reg-14);
}`, "",{"version":3,"sources":["webpack://./src/shared/components/popupMenuItem/PopupMenuItem.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,QAAA;EACA,wBAAA;EACA,kCAAA;AACJ;AAAI;EACI,mBAAA;EACA,mCAAA;AAER;AAAI;EACI,wBAAA;AAER","sourcesContent":[".menu-item {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 0 16px;\n    height: 44px;\n    gap: 8px;\n    color: var(--light-grey);\n    background-color: var(--dark-grey);\n    &:hover {\n        color: var(--white);        \n        background-color: var(--hover-grey);\n    }\n    .title {\n        font: var(--font-reg-14);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
