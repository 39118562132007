import {
    FocusEventHandler,
    InputHTMLAttributes,
    MouseEventHandler,
    useEffect,
    useRef,
    useState,
} from 'react';
import './Input.scss';
import { UseFormRegister, UseFormRegisterReturn } from 'react-hook-form';

export type InputOwnProps = {
    iconElement?: JSX.Element;
    rightIconElement?: JSX.Element;
    register?: UseFormRegister<any>;
};

export type InputProps = InputHTMLAttributes<{}> & InputOwnProps;

export const Input = ({
    iconElement,
    rightIconElement,
    register,
    className,
    ...props
}: InputProps) => {
    const [hovered, setHovered] = useState(false);
    const [focused, setFocused] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const onMouseEnter: MouseEventHandler<{}> = (event) => {
        setHovered(true);
        props.onMouseEnter?.(event);
    };

    const onMouseLeave: MouseEventHandler<{}> = (event) => {
        setHovered(false);
        props.onMouseLeave?.(event);
    };

    const onFocus: FocusEventHandler<{}> = (event) => {
        setFocused(true);
        props.onFocus?.(event);
    };

    const onBlur: FocusEventHandler<{}> = (event) => {
        setFocused(false);
        props.onBlur?.(event);
    };

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
    const [registerProps, setRegisterProps] = useState<UseFormRegisterReturn<any> | undefined>();

    useEffect(() => {
        if (register && props.name) {
            setRegisterProps(register(props.name));
        }
    }, [props.name])

    return (
        <div
            className={`input-container ${hovered && 'hover'} ${focused && 'focus'} ${className}`}
            style={props.style}
            onClick={() => inputRef?.focus()}>
            {iconElement && <div className="input-left-icon">{iconElement}</div>}
            <div className="input-container-inside" ref={ref}>
                <input
                    {...props}
                    {...(register ? register(props.name || '') : { })}
                    ref={(ref) => {
                        registerProps?.ref(ref);
                        setInputRef(ref);
                    }}
                    style={{
                        width: ref?.current?.offsetWidth || 0,
                    }}
                    autoComplete="off"
                    className="input"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </div>
            {rightIconElement && (
                <div className="input-right-icon">{rightIconElement}</div>
            )}
        </div>
    );
};
