// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
  display: flex;
  flex-direction: column;
  background-color: var(--dark-grey);
  border-bottom: var(--black) solid 1px;
  cursor: pointer;
}
.accordion:hover, .accordion.active {
  background-color: var(--hover-grey);
}
.accordion:hover .accordion-title, .accordion.active .accordion-title {
  color: var(--white);
}
.accordion:hover .accordion-score, .accordion.active .accordion-score {
  color: var(--positive-hover);
}
.accordion:first-of-type {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.accordion:last-of-type {
  border-bottom: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.accordion-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  align-items: center;
  padding: 14px 16px;
}

.accordion-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 14px 16px 16px;
  border-top: var(--black) solid 1px;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: var(--light-grey);
  font: var(--font-med-14);
}

.accordion-score {
  color: var(--positive);
  font: var(--font-reg-14);
  padding-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/accordion/Accordion.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kCAAA;EACA,qCAAA;EACA,eAAA;AACJ;AAAI;EAEI,mCAAA;AACR;AAAQ;EACI,mBAAA;AAEZ;AAAQ;EACI,4BAAA;AAEZ;AACI;EACI,4BAAA;EACA,2BAAA;AACR;AACI;EACE,mBAAA;EACE,+BAAA;EACA,8BAAA;AACR;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,uBAAA;EACA,kCAAA;AAAF;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,wBAAA;EACA,wBAAA;AAAJ;;AAGA;EACI,sBAAA;EACA,wBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".accordion {\n    display: flex;\n    flex-direction: column;\n    background-color: var(--dark-grey);\n    border-bottom: var(--black) solid 1px;\n    cursor: pointer;\n    &:hover,\n    &.active {\n        background-color: var(--hover-grey);\n        .accordion-title {\n            color: var(--white);\n        }\n        .accordion-score {\n            color: var(--positive-hover);\n        }\n    }\n    &:first-of-type {\n        border-top-right-radius: 8px;\n        border-top-left-radius: 8px;\n    }\n    &:last-of-type {\n      border-bottom: none;\n        border-bottom-right-radius: 8px;\n        border-bottom-left-radius: 8px;\n    }\n}\n\n.accordion-header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    height: fit-content;\n    align-items: center;\n    padding: 14px 16px;\n}\n\n.accordion-body {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 14px 16px 16px;\n  border-top: var(--black) solid 1px;\n}\n\n.accordion-title {\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n    align-items: center;\n    color: var(--light-grey);\n    font: var(--font-med-14);\n}\n\n.accordion-score {\n    color: var(--positive);\n    font: var(--font-reg-14);\n    padding-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
