import { createSlice } from '@reduxjs/toolkit';
import { ApiStatusType } from '../../../app/api/apiStatusType';
import { InterviewResult } from './types';
import { getUserInterviewsThunk } from './thunks';
import { InterviewModel } from './models';

export type InterviewPreparationSliceState = {
    status: ApiStatusType;
    interviews: InterviewModel[];
};

const initialState: InterviewPreparationSliceState = {
    status: ApiStatusType.IDLE,
    interviews: [],
};

export const interviewsSlice = createSlice({
    name: 'interviewResult',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserInterviewsThunk.pending, (state) => {
                state.status = ApiStatusType.PENDING;
            })
            .addCase(getUserInterviewsThunk.fulfilled, (state, { payload }) => {
                state.status = ApiStatusType.IDLE;
                state.interviews = payload;
            });
    },
});
