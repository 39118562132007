import { useRef, useState } from 'react';
import { ReactComponent as Logo } from '../../components/icons/Logo-32.svg';
import { PopupMenu } from '../popupMenu/PopupMenu';
import './Header.scss';
import { PopupMenuItem } from '../popupMenuItem/PopupMenuItem';
import { ReactComponent as ChevronBottomIcon } from '../../components/icons/ChevronBottom.svg';
import { ReactComponent as CardsIcon } from '../../components/icons/Cards.svg';
import { ReactComponent as FilterIcon } from '../../components/icons/Filter.svg';
import { ReactComponent as PhoneIcon } from '../../components/icons/Phone.svg';
import { HeaderLink } from './HeaderLink';

export type HeaderProps = {
    userName: string;
    hasRecruiterPermission?: boolean;
    logout: () => void;
    navigateToHome: () => void;
};

export const Header = ({
    userName,
    logout,
    navigateToHome,
    hasRecruiterPermission,
}: HeaderProps) => {
    const anchorElRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const onClose = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="header">
            <div className="header-left-wrapper">
                <Logo style={{ cursor: 'pointer' }} onClick={navigateToHome} />
                <div className="header-left-separator"></div>
                <HeaderLink
                    to="/interviews/"
                    Icon={CardsIcon}
                    linkTitle="Собеседования"
                />
                {hasRecruiterPermission && (
                    <HeaderLink
                        to="/interviewsCatalog/"
                        Icon={FilterIcon}
                        linkTitle="Фильтры"
                    />
                )}
                <HeaderLink to="/contacts/" Icon={PhoneIcon} linkTitle="Контакты" />
            </div>
            <div ref={anchorElRef} className="user-info" onClick={onClose}>
                <span>{userName}</span>
                <ChevronBottomIcon />
            </div>
            {anchorElRef.current && (
                <PopupMenu
                    anchorEl={anchorElRef.current}
                    open={isOpen}
                    offsetTop={16}
                    onClose={onClose}>
                    <PopupMenuItem
                        title="Logout"
                        onClick={logout}></PopupMenuItem>
                </PopupMenu>
            )}
        </div>
    );
};
