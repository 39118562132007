import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserInterviews } from './api';

export const getUserInterviewsThunk = createAsyncThunk(
    'interviews/getUserInterviews',
    async () => {
        const res = await getUserInterviews();
        return res.data;
    }
);
