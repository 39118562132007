export interface LevelModel {
    title: string;
    level: 1 | 2 | 3 | 4 | 5;
}


export const availableLevels: LevelModel[] = [
    {
        level: 1,
        title: 'Junior',
    },
    {
        level: 2,
        title: 'Junior+',
    },
    {
        level: 3,
        title: 'Middle',
    },
    {
        level: 4,
        title: 'Middle+',
    },
    {
        level: 5,
        title: 'Senior',
    },
];

export const getAllLevelsTitles = () => {
    return availableLevels.map(i => i.title);
};

export const getLevelTitle = (level: number) => {
    return availableLevels.find(i => i.level === level)?.title;
};

export const getLevelNumber = (level: string) => {
    return availableLevels.find(i => i.title === level)?.level;
};