// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interview {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--black);
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
}
.interview .interview-visualizer {
  display: flex;
  height: 200px;
  min-height: 200px;
}

.interview-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 340px;
}`, "",{"version":3,"sources":["webpack://./src/features/interview/InterviewPage.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,UAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,aAAA;EACA,iBAAA;AACR;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;AACJ","sourcesContent":[".interview {\n    position: fixed;\n    z-index: 1;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: var(--black);\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n    justify-content: center;\n    align-items: center;\n\n    .interview-visualizer {\n        display: flex;\n        height: 200px;\n        min-height: 200px;\n    }\n}\n.interview-buttons {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    width: 340px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
