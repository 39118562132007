import { apiClient } from "../../../app/api/apiClient"
import { InterviewResultModel } from "./models";
import { InterviewResult } from "./types";

export const interviewResultApi = {
    getResult: async (interviewId: number) => {
        return await apiClient.get<InterviewResultModel>(`/interview/${interviewId}/result/`);
    },
    getIsResultEmpty: async (interviewId: number) => {
        return await apiClient.get<{ empty: false }>(`/interview/${interviewId}/result/empty/`);
    },
}

export const getUserContacts = ({userId}: {userId: number}) => {
    return apiClient.get(`/auth/profile/${userId}/`);
};