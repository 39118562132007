import {
  selectInterviewPreparationDifficulties,
    selectInterviewPreparationDifficulty,
} from '../core/selectors';
import { SelectOption } from '../../../shared/components/selectOption/SelectOption';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectDifficultyAction } from '../core/slice';
import './DifficultiesList.scss'

export const DifficultiesList = () => {
    const dispatch = useAppDispatch();

    const difficulties = useAppSelector(
        selectInterviewPreparationDifficulties
    );
    const difficulty = useAppSelector(
      selectInterviewPreparationDifficulty
    );

    const onSelect = (id: number) => {
        dispatch(selectDifficultyAction(id));
    };

    return (
        <div className="difficulties-list">
            {difficulties.map((i) => (
                <SelectOption
                    key={i.level}
                    selected={i.level === difficulty?.level}
                    title={i.title}
                    onSelect={() => onSelect(i.level)}
                />
            ))}
        </div>
    );
};