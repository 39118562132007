// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interview-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: var(--dark-grey);
  border-radius: 8px;
  height: 120px;
  box-sizing: border-box;
  cursor: pointer;
}
.interview-card:hover {
  background-color: var(--hover-grey);
}
.interview-card .title {
  font: var(--font-med-14);
  color: var(--white);
  margin-bottom: 2px;
}
.interview-card .pass-time {
  font: var(--font-reg-12);
  color: var(--light-grey);
  margin-bottom: 16px;
}
.interview-card .property {
  display: flex;
  gap: 8px;
  color: var(--light-grey);
  font: var(--font-reg-14);
}
.interview-card .property-level {
  margin-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/features/interviews/components/InterviewCard.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,kCAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;AACJ;AACI;EACI,mCAAA;AACR;AAEI;EACI,wBAAA;EACA,mBAAA;EACA,kBAAA;AAAR;AAGI;EACI,wBAAA;EACA,wBAAA;EACA,mBAAA;AADR;AAII;EACI,aAAA;EACA,QAAA;EACA,wBAAA;EACA,wBAAA;AAFR;AAKI;EACI,kBAAA;AAHR","sourcesContent":[".interview-card {\n    display: flex;\n    flex-direction: column;\n    padding: 16px;\n    background-color: var(--dark-grey);\n    border-radius: 8px;\n    height: 120px;\n    box-sizing: border-box;\n    cursor: pointer;\n\n    &:hover {\n        background-color: var(--hover-grey);\n    }\n\n    .title {\n        font: var(--font-med-14);\n        color: var(--white);\n        margin-bottom: 2px;\n    }\n\n    .pass-time {\n        font: var(--font-reg-12);\n        color: var(--light-grey);\n        margin-bottom: 16px;\n    }\n\n    .property {\n        display: flex;\n        gap: 8px;\n        color: var(--light-grey);\n        font: var(--font-reg-14);\n    }\n\n    .property-level {\n        margin-bottom: 8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
