import { useEffect, useState } from 'react';
import './SelectInput.scss';
import { Input, InputProps } from '../input/Input';
import { ReactComponent as ChevronBottomIcon } from '../icons/ChevronBottom.svg';
import { ReactComponent as ChevronTopIcon } from '../icons/ChevronTop.svg';

type SelectInputOwnProps = {
    options: string[];
    onSelectOption: (query: string) => void;
};

export type SelectInputProps = InputProps & SelectInputOwnProps;

export const SelectInput: React.FC<SelectInputProps> = ({
    options,
    className,
    onSelectOption,
    ...props
}) => {
    const [filteredOptions, setFilteredOptions] = useState<string[]>(options);
    const [focus, setFocus] = useState<boolean>(false);
    const [value, setValue] = useState('');

    const onInputChange = ({ value }: { value: string }) => {
        setValue(value);
    };

    useEffect(() => {
        setFilteredOptions(
            !value
                ? options
                : options.filter((i) =>
                      i.toLowerCase().includes(value.toLowerCase())
                  )
        );
    }, [value, options]);

    return (
        <div className={'select-input ' + className}>
            <Input
                {...props}
                name="query"
                value={value}
                rightIconElement={
                    focus ? <ChevronTopIcon /> : <ChevronBottomIcon />
                }
                onFocus={() => {
                    setFocus(true);
                }}
                onBlur={({ target }) => {
                    setFocus(false);
                    const value = (target as HTMLInputElement).value;
                    if (options.includes(value) || !value) {
                        onSelectOption(value);
                    } else {
                        onInputChange({ value: filteredOptions[0] || '' });
                        onSelectOption(filteredOptions[0] || '');
                    }
                }}
                onChange={({ target }) => {
                    onInputChange(target as HTMLInputElement);
                }}
                onKeyDown={({ target, key }) => {
                    if (key !== 'Enter') {
                        return;
                    }
                    (target as HTMLInputElement).blur();
                }}
            />
            {focus && (
                <div className="select-input-labels-container">
                    {filteredOptions.map((i, id) => (
                        <div
                            key={id}
                            className="select-input-option"
                            onMouseDown={() => {
                                setValue(i);
                                onSelectOption(i);
                            }}>
                            {i}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
