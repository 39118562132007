import { createAsyncThunk } from '@reduxjs/toolkit';
import { interviewApi } from './api';
import {
    CompleteInterviewThunkData,
    GetQuestionThunkData,
    SendAnswerThunkData,
} from './types';

export const getQuestionThunk = createAsyncThunk<
    {
        answerId: number;
        audioURL: string;
    },
    GetQuestionThunkData,
    { rejectValue: number }
>('interview/getQuestion', async (data: GetQuestionThunkData, thunkAPI) => {
    try {
        const question = await interviewApi.getQuestion(data.interviewId);
        const audio = await interviewApi.getQuestionAudio(
            question.data.question_id
        );

        return {
            answerId: question.data.answer_id,
            audioURL: URL.createObjectURL(audio.data),
        };
    } catch (error) {
        const code = (<any>error).status;
        return thunkAPI.rejectWithValue(Number(code));
    }
});

export const submitAnswerThunk = createAsyncThunk(
    'interview/submitAnswer',
    async (data: SendAnswerThunkData) => {
        const res = await interviewApi.submitAnswer(
            data.answerId,
            data.formData
        );
        return res.data;
    }
);

export const completeInterviewThunk = createAsyncThunk(
    'interview/complete',
    async (data: CompleteInterviewThunkData) => {
        const res = await interviewApi.completeInterview(data.interviewId);
        return res.data;
    }
);
