import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { authSlice } from '../features/auth/core/slice';
import { interviewPreparationSlice } from '../features/interviewPreparation/core/slice';
import { interviewResultSlice } from '../features/interviewResult/core/slice';
import { interviewsSlice } from '../features/interviews/core/slice';
import { interviewSlice } from '../features/interview/core/slice';
import { interviewsCatalogSlice } from '../features/interviewsCatalog/core/slice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    interviewResult: interviewResultSlice.reducer,
    interviewPreparation: interviewPreparationSlice.reducer,
    interviews: interviewsSlice.reducer,
    interview: interviewSlice.reducer,
    interviewsCatalog: interviewsCatalogSlice.reducer,
  }
});

// Infer the type of `store`
export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch'];
// Define a reusable type describing thunk functions
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;