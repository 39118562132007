import { Header } from './shared/components/header/Header';
import { AppRoutes } from './AppRoutes';
import './App.scss';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
    selectCurrentAuthInProgress,
    selectCurrentUserName,
    selectIsAuth,
    selectRecruiterPermission,
} from './features/auth/core/selectors';
import { useEffect } from 'react';
import {
    getCSRFThunk,
    getUserThunk,
    logoutThunk,
} from './features/auth/core/thunks';
import { useNavigate } from 'react-router-dom';

const App = () => {
    const isAuth = useAppSelector(selectIsAuth);
    const isAuthInProgress = useAppSelector(selectCurrentAuthInProgress);

    const userName = useAppSelector(selectCurrentUserName);
    const hasRecruiterPermission = useAppSelector(selectRecruiterPermission);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const authorizedClassName = isAuth ? 'app-authorized' : 'app-unauthorized';

    useEffect(() => {
        dispatch(getCSRFThunk());
        dispatch(getUserThunk());
    }, []);

    useEffect(() => {
        if (
            isAuth &&
            !isAuthInProgress &&
            window.location.pathname.includes('/auth/')
        ) {
            dispatch(getCSRFThunk());
            navigate('/interviews/');
        }
        if (
            !isAuth &&
            !isAuthInProgress &&
            !window.location.pathname.includes('/auth/')
        ) {
            dispatch(getCSRFThunk());
            navigate('/auth/');
        }
    }, [isAuthInProgress, isAuth]);

    return !isAuthInProgress ? (
        <div className={`app ${authorizedClassName}`}>
            {isAuth && userName && (
                <Header
                    userName={userName}
                    hasRecruiterPermission={hasRecruiterPermission}
                    logout={() => dispatch(logoutThunk())}
                    navigateToHome={() => navigate('/')}
                />
            )}
            <AppRoutes />
        </div>
    ) : (
        <>Loading....</>
    );
};

export default App;
