// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.difficulties-list {
  background-color: var(--dark-grey);
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/features/interviewPreparation/components/DifficultiesList.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,kBAAA;AACJ","sourcesContent":[".difficulties-list {\n    background-color: var(--dark-grey);\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
