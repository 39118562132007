import { AudioVisualizer, LiveAudioVisualizer } from 'react-audio-visualize';
import { Button } from '../../shared/components/button/Button';
import { useEffect, useRef, useState } from 'react';
import './InterviewPage.scss';
import { useRecorder } from './utils/useRecorder';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    completeInterviewThunk,
    getQuestionThunk,
    submitAnswerThunk,
} from './core/thunks';
import {
    selectInterviewAnswerId,
    selectInterviewQuestionURL,
    selectInterviewStatus,
} from './core/selectors';
import { clearAction, InterviewStatus, startRecordingAction } from './core/slice';

export const InterviewPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { id: interviewId } = useParams<{ id: string }>();
    const { mediaRecorder, startRecording, stopRecording, file, clear, permission } =
        useRecorder();

    const status = useAppSelector(selectInterviewStatus);
    const answerId = useAppSelector(selectInterviewAnswerId);
    const questionAudioURL = useAppSelector(selectInterviewQuestionURL);

    const [questionAudioBlob, setQuestionAudioBlob] = useState<Blob | null>(
        null
    );

    const [audioTime, setAudioTime] = useState<number | undefined>();
    const audioElement = useRef<HTMLAudioElement | null>(null);

    const skipAnswer = () => {
        if (answerId === null) {
            return;
        }
        stopRecording();
        handleSubmitAnswer(answerId);
    };

    const handleSubmitAnswer = (answerId: number, formData?: FormData) => {
        dispatch(submitAnswerThunk({ answerId, formData }));
    };

    const onAudioEnded = () => {
        dispatch(startRecordingAction());
    };

    const onTimeUpdate = () => {
        setAudioTime(audioElement!.current!.currentTime);
    };

    const onEndInterview = () => {
        if (!isNaN(Number(answerId))) {
            handleSubmitAnswer(Number(answerId));
        }

        if (!isNaN(Number(interviewId))) {
            dispatch(completeInterviewThunk({
                interviewId: Number(interviewId)
            }));
        }
    };

    useEffect(() => {
        if (questionAudioURL) {
            fetch(questionAudioURL)
                .then((response) => response.blob())
                .then(setQuestionAudioBlob);
        }
    }, [questionAudioURL]);

    useEffect(() => {
        if (answerId === null || file === null || !permission) {
            return;
        }
        const formData = new FormData();
        formData.append('audio_file', file);
        handleSubmitAnswer(answerId, formData);
    }, [file]);

    useEffect(() => {
        if (!permission) {
            return;
        }
        if (status === InterviewStatus.REDIRECT_TO_RESULTS) {
            navigate(`/interview/result/${interviewId}`);
        }
        if (status === InterviewStatus.INTERVIEW_COMPLETE) {
            dispatch(
                completeInterviewThunk({ interviewId: Number(interviewId) })
            );
            clear();
        }
        if (status === InterviewStatus.NEED_TO_GET_NEW_QUESTION) {
            dispatch(getQuestionThunk({ interviewId: Number(interviewId) }));
        }
        if (status === InterviewStatus.RECORD_ANSWER) {
            startRecording();
        }
    }, [status, permission]);

    useEffect(() => {
        return () => {
            dispatch(clearAction());
        };
    }, []);

    return (
        <div className="interview">
            <div className='interview-visualizer'>
                {mediaRecorder && (
                    <LiveAudioVisualizer
                        mediaRecorder={mediaRecorder}
                        width={window.innerWidth > 340 ? 340 : window.innerWidth}
                        height={200}
                        barColor="#3656F6"
                        barWidth={4}
                        gap={8}
                        fftSize={1024}
                        smoothingTimeConstant={0.4}
                        minDecibels={-90}
                    />
                )}
                {questionAudioURL && questionAudioBlob && (
                    <>
                        <AudioVisualizer
                            blob={questionAudioBlob}
                            width={window.innerWidth > 340 ? 340 : window.innerWidth}
                            height={200}
                            barColor="#3656F6"
                            barPlayedColor="#4b6fff"
                            barWidth={1}
                            gap={2}
                            currentTime={audioTime}
                        />
                        <audio
                            ref={audioElement}
                            style={{ display: 'none' }}
                            src={questionAudioURL}
                            autoPlay
                            onEnded={onAudioEnded}
                            onTimeUpdate={onTimeUpdate}
                        />
                    </>
                )}
            </div>
            <div className="interview-buttons">
                <Button styleType="positive" onClick={() => stopRecording()}>
                    Отправить голосовой ответ
                </Button>
                <Button styleType="grey" onClick={skipAnswer}>
                    Пропустить вопрос
                </Button>
                <Button styleType="negative" onClick={onEndInterview}>
                    Завершить собеседование
                </Button>
            </div>
        </div>
    );
};
