// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background-color: var(--dark-grey);
  border-bottom: var(--black) solid 1px;
}

.header-left-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.header-left-separator {
  height: 44px;
  min-width: 1px;
  width: 1px;
  display: flex;
  background-color: var(--middle-grey);
}

.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--light-grey);
  font: var(--font-reg-14);
  height: 100%;
  gap: 8px;
}
.user-info:hover {
  color: var(--white);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/header/Header.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,kCAAA;EACA,qCAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AACJ;;AAEA;EACI,YAAA;EACA,cAAA;EACA,UAAA;EACA,aAAA;EACA,oCAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,wBAAA;EACA,wBAAA;EACA,YAAA;EACA,QAAA;AACJ;AAAI;EACI,mBAAA;EACA,eAAA;AAER","sourcesContent":[".header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 16px;\n    background-color: var(--dark-grey);\n    border-bottom: var(--black) solid 1px;\n}\n\n.header-left-wrapper {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 16px;\n}\n\n.header-left-separator {\n    height: 44px;\n    min-width: 1px;\n    width: 1px;\n    display: flex;\n    background-color: var(--middle-grey);\n}\n\n.user-info {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    color: var(--light-grey);\n    font: var(--font-reg-14);\n    height: 100%;\n    gap: 8px;\n    &:hover {\n        color: var(--white);\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
