import { createSlice } from '@reduxjs/toolkit';
import { ApiStatusType } from '../../../app/api/apiStatusType';
import { UserModel } from './models';
import {
    getCSRFThunk,
    getUserThunk,
    loginThunk,
    logoutThunk,
    signupThunk,
} from './thunks';

export type AccountReducerState = {
    isAuthInProgress: boolean;
    status: ApiStatusType;
    user: UserModel | null;
    message: string | null;
    CSRFToken: string | null;
};

const initialState: AccountReducerState = {
    status: ApiStatusType.PENDING,
    user: null,
    message: null,
    CSRFToken: null,
    isAuthInProgress: true,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCSRFThunk.fulfilled, (state, { payload: csrf }) => {
                state.CSRFToken = csrf;
            })
            .addCase(getUserThunk.rejected, (state, { payload: user }) => {
                state.status = ApiStatusType.IDLE;
                state.isAuthInProgress = false;
            })
            .addCase(getUserThunk.fulfilled, (state, { payload: user }) => {
                state.status = ApiStatusType.IDLE;
                state.user = user;
                state.isAuthInProgress = false;
            })
            .addCase(loginThunk.pending, (state) => {
                state.status = ApiStatusType.PENDING;
            })
            .addCase(signupThunk.pending, (state) => {
                state.status = ApiStatusType.PENDING;
            })
            .addCase(loginThunk.rejected, (state, action) => {
                state.status = ApiStatusType.FAILED;
                state.message = action.error.message || null;
            })
            .addCase(signupThunk.rejected, (state, action) => {
                state.status = ApiStatusType.FAILED;
                state.message = action.error.message || null;
            })
            .addCase(loginThunk.fulfilled, (state, { payload: user }) => {
                state.status = ApiStatusType.SUCCESS;
                state.user = user;
                state.isAuthInProgress = false;
            })
            .addCase(signupThunk.fulfilled, (state) => {
                state.status = ApiStatusType.IDLE;
            })
            .addCase(logoutThunk.fulfilled, (state) => {
                state.status = ApiStatusType.IDLE;
                state.user = null;
            });
    },
});
