import React, { HTMLAttributes } from 'react';
import { InterviewModel } from '../core/models';
import './InterviewNewCard.scss';
import { ReactComponent as PlusIcon } from '../../../shared/components/icons/Plus.svg';

export type InterviewCardOwnProps = {};

export type InterviewCardProps = HTMLAttributes<EventTarget> &
    InterviewCardOwnProps;

export const InterviewNewCardComponent: React.FC<InterviewCardProps> = ({
    className,
    ...props
}) => {
    return (
        <div {...props} className={'interview-new-card ' + className}>
            <PlusIcon className="icon" />
            <div className="title">Пройти собеседование</div>
        </div>
    );
};
