// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px 16px;
}
.contacts .contacts-title {
  color: var(--white);
  font: var(--font-med-19);
}
.contacts .contacts-row {
  display: flex;
  flex-direction: row;
  color: var(--light-grey);
  font: var(--font-reg-14);
}`, "",{"version":3,"sources":["webpack://./src/features/contacts/Contacts.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;AACJ;AACI;EACI,mBAAA;EACA,wBAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,wBAAA;EACA,wBAAA;AAAR","sourcesContent":[".contacts {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    padding: 24px 16px 16px;\n\n    .contacts-title {\n        color: var(--white);\n        font: var(--font-med-19);\n    }\n\n    .contacts-row {\n        display: flex;\n        flex-direction: row;\n        color: var(--light-grey);\n        font: var(--font-reg-14);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
