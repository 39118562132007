// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-input {
  display: flex;
  flex-direction: column;
  height: 44px;
  position: relative;
}
.select-input .select-input-labels-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 220px;
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  top: 45px;
  width: 100%;
  z-index: 1;
}
.select-input .select-input-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  background-color: var(--black);
  font: var(--font-reg-14);
  color: var(--light-grey);
  height: 44px;
  flex-shrink: 0;
  width: 100%;
  cursor: pointer;
}
.select-input .select-input-option:hover {
  color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/shared/components/selectInput/SelectInput.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,wBAAA;EACA,wBAAA;EACA,YAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;AAAR;AAEQ;EACI,mBAAA;AAAZ","sourcesContent":[".select-input {\n    display: flex;\n    flex-direction: column;\n    height: 44px;\n    position: relative;\n\n    .select-input-labels-container {\n        position: absolute;\n        display: flex;\n        flex-direction: column;\n        max-height: calc(44px * 5);\n        height: fit-content;\n        overflow-y: auto;\n        overflow-x: hidden;\n        top: 45px;\n        width: 100%;\n        z-index: 1;\n    }\n\n    .select-input-option {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        padding: 0 16px;\n        background-color: var(--black);\n        font: var(--font-reg-14);\n        color: var(--light-grey);\n        height: 44px;\n        flex-shrink: 0;\n        width: 100%;\n        cursor: pointer;\n\n        &:hover {\n            color: var(--white);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
