// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interviews-catalog-outside-wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 44px);
  padding: 24px 16px 16px;
  box-sizing: border-box;
}

.interviews-catalog-wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--dark-grey);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.interviews-catalog-filters {
  position: sticky;
  top: 0;
  background-color: var(--dark-grey);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  gap: 8px;
  flex-shrink: 0;
}

.header-title-main {
  background-color: var(--black);
  color: var(--white);
  font: var(--font-med-19);
  align-self: start;
  padding-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/features/interviewsCatalog/InterviewsCatalogPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,0BAAA;EACA,uBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,kCAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,MAAA;EACA,kCAAA;EACA,aAAA;EACA,4DAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,QAAA;EACA,cAAA;AACJ;;AAEA;EACI,8BAAA;EACA,mBAAA;EACA,wBAAA;EACA,iBAAA;EACA,oBAAA;AACJ","sourcesContent":[".interviews-catalog-outside-wrapper {\n    display: flex;\n    flex-direction: column;\n    width: 100vw;\n    height: calc(100vh - 44px);\n    padding: 24px 16px 16px;\n    box-sizing: border-box;\n}\n\n.interviews-catalog-wrapper {\n    display: flex;\n    flex-direction: column;\n    background-color: var(--dark-grey);\n    border-radius: 8px;\n    width: 100%;\n    height: 100%;\n    overflow-y: auto;\n    overflow-x: hidden;\n}\n\n.interviews-catalog-filters {\n    position: sticky;\n    top: 0;\n    background-color: var(--dark-grey);\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));\n    width: 100%;\n    box-sizing: border-box;\n    padding: 12px 16px;\n    gap: 8px;\n    flex-shrink: 0;\n}\n\n.header-title-main {\n    background-color: var(--black);\n    color: var(--white);\n    font: var(--font-med-19);\n    align-self: start;\n    padding-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
