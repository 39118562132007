import { useNavigate } from 'react-router-dom';
import { Button } from '../../shared/components/button/Button';
import './InterviewsPage.scss';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getUserInterviewsThunk } from './core/thunks';
import { selectInterviews, selectStatus } from './core/selectors';
import { InterviewCardComponent } from './components/InterviewCard';
import { ApiStatusType } from '../../app/api/apiStatusType';
import { InterviewNewCardComponent } from './components/InterviewNewCard';

export const InterviewsPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const interviews = useAppSelector(selectInterviews);
    const status = useAppSelector(selectStatus);

    const onClick = (event: any) => {
        navigate('/interview/preparation');
    };

    useEffect(() => {
        dispatch(getUserInterviewsThunk());
    }, []);

    if (status === ApiStatusType.PENDING) {
        return <></>;
    }

    const interviewsCards = interviews.map((i) => (
        <InterviewCardComponent
            key={i.id}
            interview={i}
            onClick={() =>
                navigate('/interview/result/' + i.id)
            }
        />
    ));

    if (interviews.length) {
        return (
            <div className="interviews">
                <div className='header-title-main'>Мои собеседования</div>
                <div className="container-grid">
                    <InterviewNewCardComponent onClick={onClick} />
                    {interviewsCards}
                </div>
            </div>
        );
    }

    return (
        <div className="interviews">
            <div className="empty-status-container">
                <div className="header-title">
                    Пока тут нет собеседования. Начать первое собеседование?
                </div>
                <Button styleType="positive" onClick={onClick}>
                    Перейти к собеседованию
                </Button>
            </div>
        </div>
    );
};
