import { InputHTMLAttributes, useState } from 'react';
import './RadioButton.scss';

import { ReactComponent as RadioButtonDefault } from './svg/RadioButton.svg';
import { ReactComponent as RadioButtonActive } from './svg/RadioButtonActive.svg';
import { ReactComponent as RadioButtonHover } from './svg/RadioButtonHover.svg';
import { ReactComponent as RadioButtonActiveHover } from './svg/RadioButtonActiveHover.svg';

type RadioButtonOwnProps = {
    active: boolean;
};

export type RadioButtonProps = InputHTMLAttributes<{}> & RadioButtonOwnProps;

export const RadioButton = ({ active, ...props }: RadioButtonProps) => {
    const [hover, setHover] = useState(false);

    const getSvg = () => {
        if (active && hover) {
            return <RadioButtonActiveHover />;
        }
        if (hover) {
            return <RadioButtonHover />;
        }
        if (active) {
            return <RadioButtonActive />;
        }
        return <RadioButtonDefault />;
    };

    return (
        <span
            className="radio-button"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            {getSvg()}
        </span>
    );
};
