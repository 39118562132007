import { createSlice } from '@reduxjs/toolkit';
import { ApiStatusType } from '../../../app/api/apiStatusType';
import { InterviewsFilters } from './types';
import { InterviewModel, SpecializationModel } from './models';
import { getAllInterviewsThunk, getInterviewsWithFiltersThunk, getSpecializationsThunk } from './thunks';

export type InterviewPreparationSliceState = {
    status: ApiStatusType;
    interviews: InterviewModel[];
    specializations: SpecializationModel[];
    filters: InterviewsFilters;
};

const initialState: InterviewPreparationSliceState = {
    status: ApiStatusType.IDLE,
    interviews: [],
    specializations: [],
    filters: { }
};

export const interviewsCatalogSlice = createSlice({
    name: 'interviewResult',
    initialState,
    reducers: {
        setSpecializationFilter: (state, {payload}: {payload: InterviewsFilters['specialization_id']}) => {
            if (payload) {
                state.filters.specialization_id = payload;
            } else {
                delete state.filters.specialization_id;
            }
        },
        setDifficultyFilter: (state, {payload}: {payload: InterviewsFilters['difficulty']}) => {
            if (payload) {
                state.filters.difficulty = payload;
            } else {
                delete state.filters.difficulty;
            }
        },
        setScoreMinFilter: (state, {payload}: {payload: InterviewsFilters['score_min']}) => {
            if (payload) {
                state.filters.score_min = payload;
            } else {
                delete state.filters.score_min;
            }
        },
        setScoreMaxFilter: (state, {payload}: {payload: InterviewsFilters['score_max']}) => {
            if (payload) {
                state.filters.score_max = payload;
            } else {
                delete state.filters.score_max;
            }
        },
        clearFilters: (state, action) => {
            state.filters = { };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllInterviewsThunk.pending, (state) => {
                state.status = ApiStatusType.PENDING;
                state.interviews = [];
            })
            .addCase(getAllInterviewsThunk.fulfilled, (state, { payload }) => {
                state.status = ApiStatusType.IDLE;
                state.interviews = payload.results;
            })
            .addCase(getInterviewsWithFiltersThunk.pending, (state) => {
                state.status = ApiStatusType.PENDING;
                state.interviews = [];
            })
            .addCase(getInterviewsWithFiltersThunk.fulfilled, (state, { payload }) => {
                state.status = ApiStatusType.IDLE;
                state.interviews = payload.results;
            })
            .addCase(getSpecializationsThunk.pending, (state) => {
                state.status = ApiStatusType.PENDING;
            })
            .addCase(getSpecializationsThunk.fulfilled, (state, { payload }) => {
                state.status = ApiStatusType.IDLE;
                state.specializations = payload;
            });
    },
});
export const setSpecializationFilterAction = interviewsCatalogSlice.actions.setSpecializationFilter;
export const setDifficultyFilterAction = interviewsCatalogSlice.actions.setDifficultyFilter;
export const setScoreMinFilterAction = interviewsCatalogSlice.actions.setScoreMinFilter;
export const setScoreMaxFilterAction = interviewsCatalogSlice.actions.setScoreMaxFilter;
export const clearFiltersAction = interviewsCatalogSlice.actions.clearFilters;