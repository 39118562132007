import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthPage } from './features/auth/AuthPage';
import { Login } from './features/auth/Login';
import { Signup } from './features/auth/Signup';
import { InterviewsPage } from './features/interviews/InterviewsPage';
import { InterviewPreparationPage } from './features/interviewPreparation/InterviewPreparationPage';
import { InterviewPage } from './features/interview/InterviewPage';
import { InterviewResultPage } from './features/interviewResult/InterviewResultPage';
import { InterviewsCatalogPage } from './features/interviewsCatalog/InterviewsCatalogPage';
import { Contacts } from './features/contacts/Contacts';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route
                path="/*"
                element={
                    <Routes>
                        <Route
                            path="*"
                            element={<Navigate to='/interviews/' />}
                        />
                        <Route
                            path="/interviews/"
                            element={<InterviewsPage />}
                        />
                        <Route
                            path="/interview/:id"
                            element={<InterviewPage />}
                        />
                        <Route
                            path="/interview/preparation"
                            element={<InterviewPreparationPage />}
                        />
                        <Route
                            path="/interview/result/:id"
                            element={<InterviewResultPage />}
                        />
                        <Route
                            path="/interviewsCatalog/"
                            element={<InterviewsCatalogPage />}
                        />
                        <Route
                            path="/contacts/"
                            element={<Contacts />}
                        />
                    </Routes>
                }
            />
            <Route path="/auth/" element={<AuthPage />}>
                <Route path="" element={<Navigate to="login" />} />
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />
            </Route>
        </Routes>
    );
};
