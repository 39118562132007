import React, { HTMLAttributes } from 'react';
import './PopupMenuItem.scss';

type PopupItemOwnProps = {
    title: string;
    leftIcon?: JSX.Element;
};
export type PopupMenuProps = HTMLAttributes<EventTarget> & PopupItemOwnProps;

export const PopupMenuItem: React.FC<PopupMenuProps> = ({
    title,
    leftIcon,
    className,
    ...props
}) => {
    return (
        <div className={'menu-item ' + className || ''} {...props}>
            {leftIcon && <div className="left-icon">{leftIcon}</div>}
            <div className="title">{title}</div>
        </div>
    );
};
