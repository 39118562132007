import { createSlice } from '@reduxjs/toolkit';
import { completeInterviewThunk, getQuestionThunk, submitAnswerThunk } from './thunks';

export enum InterviewStatus {
    LISTENING_QUESTION,
    RECORD_ANSWER,
    SEND_ANSWER,
    NEED_TO_GET_NEW_QUESTION,
    INTERVIEW_COMPLETE,
    REDIRECT_TO_RESULTS,
}

export type InterviewSliceState = {
    status: InterviewStatus;
    currentQuestionURL: string | null;
    currentAnswerId: number | null;
};

const initialState: InterviewSliceState = {
    status: InterviewStatus.NEED_TO_GET_NEW_QUESTION,
    currentQuestionURL: null,
    currentAnswerId: null,
};

export const interviewSlice = createSlice({
    name: 'interview',
    initialState,
    reducers: {
        startRecording: (state) => {
            state.status = InterviewStatus.RECORD_ANSWER;
            state.currentQuestionURL = null;
        },
        clear: (state) => {
            state.status = InterviewStatus.NEED_TO_GET_NEW_QUESTION;
            state.currentQuestionURL = null;
            state.currentAnswerId = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestionThunk.fulfilled, (state, { payload }) => {
                state.status = InterviewStatus.LISTENING_QUESTION;
                state.currentAnswerId = payload.answerId;
                state.currentQuestionURL = payload.audioURL;
            })
            .addCase(getQuestionThunk.rejected, (state, { payload }) => {
                if (payload === 400) {
                    state.status = InterviewStatus.INTERVIEW_COMPLETE;
                    return;
                }
            })
            .addCase(submitAnswerThunk.pending, (state, { payload }) => {
                state.status = InterviewStatus.SEND_ANSWER;
            })
            .addCase(submitAnswerThunk.fulfilled, (state, { payload }) => {
                state.status = InterviewStatus.NEED_TO_GET_NEW_QUESTION;
            })
            .addCase(completeInterviewThunk.fulfilled, (state, { payload }) => {
                state.status = InterviewStatus.REDIRECT_TO_RESULTS;
            });
    },
});

export const startRecordingAction = interviewSlice.actions.startRecording;
export const clearAction = interviewSlice.actions.clear;
