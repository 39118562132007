import { RefAttributes, useEffect, useState } from 'react';
import './HeaderLink.scss';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

type HeaderLinkOwnProps = {
    Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string;
        }
    >;
    linkTitle: string;
    isActive?: boolean;
};

export type HeaderLinkProps = HeaderLinkOwnProps & NavLinkProps & RefAttributes<HTMLAnchorElement>;

export const HeaderLink: React.FC<HeaderLinkProps> = ({
    Icon,
    linkTitle,
    ...props
}) => {
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(location.pathname === props.to);
    }, [location]);

    return (
        <NavLink
            {...props}
            className={
                'header-link ' + (isActive && 'active ') + props.className
            }>
            <Icon />
            <div className="header-link-title">{linkTitle}</div>
        </NavLink>
    );
};
