import './Contacts.scss';

export const Contacts = () => {
    return (
        <div className="contacts">
            <div className="contacts-title">
                Контакты
            </div>
            <div className="contacts-row">
                По общим вопросам: artem.kopylov@urfu.me, Артем Копылов
            </div>
            <div className="contacts-row">
                По технической части: m.v.kulikov@urfu.me, Михаил Куликов
            </div>
            <div className="contacts-row">
                © Digital Inc. - 2025
            </div>
        </div>
    );
};