import { apiClient } from '../../../app/api/apiClient';

export type QuestionResponse = {
    question_id: number;
    answer_id: number;
    question: string;
};

export enum SubmitAnswerStatus {
    INTRVIEW_PROCCESS = 0,
    INTERVIEW_DONE = 1,
}

export const interviewApi = {
    getQuestion: async (interviewId: number) => {
        return await apiClient.get<QuestionResponse>(
            `/interview/${interviewId}/question/`
        );
    },
    getQuestionAudio: async (questionId: number) => {
        return await apiClient.get<Blob>(
            `/interview/question/${questionId}/audio/`,
            { responseType: 'blob' }
        );
    },
    submitAnswer: async (answerId: number, formData?: FormData) => {
        return await apiClient.post<{ status: SubmitAnswerStatus }>(
            `/interview/answer/${answerId}/`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
    },
    completeInterview: async (interviewId: number) => {
        return await apiClient.post(`/interview/${interviewId}/complete/`);
    },
};
