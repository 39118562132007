import { apiClient } from '../../../app/api/apiClient';
import { SpecializationModel } from './models';
import { StartInterviewData } from './types';

export const interviewApi = {
    getSpecializations: async () => {
        return await apiClient.get<SpecializationModel[]>(
            '/interview/specialization_list/'
        );
    },
    startInterview: async (data: StartInterviewData) => {
        return await apiClient.post<{ id: number }>('/interview/start/', data);
    },
};
