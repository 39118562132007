import { createSlice } from '@reduxjs/toolkit';
import { ApiStatusType } from '../../../app/api/apiStatusType';
import { getInterviewResultThunk } from './thunks';
import { InterviewResultModel } from './models';

export type InterviewPreparationSliceState = {
    status: ApiStatusType;
    result: InterviewResultModel | null;
    isResultEmpty: boolean;
};

const initialState: InterviewPreparationSliceState = {
    status: ApiStatusType.IDLE,
    result: null,
    isResultEmpty: true
};

export const interviewResultSlice = createSlice({
    name: 'interviewResult',
    initialState,
    reducers: {
        clearState: (state, action) => {
            state.isResultEmpty = true;
            state.result = null;
            state.status = ApiStatusType.IDLE;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInterviewResultThunk.pending, (state) => {
                state.status = ApiStatusType.PENDING;
            })
            .addCase(getInterviewResultThunk.fulfilled, (state, { payload }) => {
                state.status = ApiStatusType.IDLE;
                state.isResultEmpty = false;
                state.result = payload;
            })
            .addCase(getInterviewResultThunk.rejected, (state, { payload }) => {
                state.status = ApiStatusType.FAILED;
                if (payload === 400) {
                    state.isResultEmpty = true;
                }
            })
    },
});

export const clearStateAction = interviewResultSlice.actions.clearState;