// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --black: #0e0e0f;
  --dark-grey: #1b1c1e;
  --hover-grey: #242426;
  --middle-grey: #2d2e35;
  --grey: #505363;
  --light-grey: #a4a6b4;
  --white: #e8eaf6;
  --positive: #3453f0;
  --positive-hover: #4b6fff;
  --positive-disabled: #475cad;
  --negative: #D22E2E;
  --negative-hover: #FD3F3F;
  --negative-disabled: #AE3F3F;
  --font-reg-12: 400 12px "Raleway";
  --font-med-12: 600 12px "Raleway";
  --font-reg-14: 400 14px "Raleway";
  --font-med-14: 600 14px "Raleway";
  --font-reg-19: 400 19px "Raleway";
  --font-med-19: 600 19px "Raleway";
}

body,
#root {
  margin: 0;
  padding: 0;
  background-color: var(--black);
  height: 100vh;
  width: 100vw;
  font-family: "Raleway";
  letter-spacing: 0.3px;
  overflow: hidden;
}

#menuRoot {
  position: absolute;
  top: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,oBAAA;EACA,qBAAA;EACA,sBAAA;EACA,eAAA;EACA,qBAAA;EACA,gBAAA;EAEA,mBAAA;EACA,yBAAA;EACA,4BAAA;EAEA,mBAAA;EACA,yBAAA;EACA,4BAAA;EAEA,iCAAA;EACA,iCAAA;EAEA,iCAAA;EACA,iCAAA;EAEA,iCAAA;EACA,iCAAA;AALJ;;AAQA;;EAEI,SAAA;EACA,UAAA;EACA,8BAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,qBAAA;EACA,gBAAA;AALJ;;AAQA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;AALJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');\n\n:root {\n    --black: #0e0e0f;\n    --dark-grey: #1b1c1e;\n    --hover-grey: #242426;\n    --middle-grey: #2d2e35;\n    --grey: #505363;\n    --light-grey: #a4a6b4;\n    --white: #e8eaf6;\n\n    --positive: #3453f0;\n    --positive-hover: #4b6fff;\n    --positive-disabled: #475cad;\n\n    --negative: #D22E2E;\n    --negative-hover: #FD3F3F;\n    --negative-disabled: #AE3F3F;\n\n    --font-reg-12: 400 12px 'Raleway';\n    --font-med-12: 600 12px 'Raleway';\n\n    --font-reg-14: 400 14px 'Raleway';\n    --font-med-14: 600 14px 'Raleway';\n\n    --font-reg-19: 400 19px 'Raleway';\n    --font-med-19: 600 19px 'Raleway';\n}\n\nbody,\n#root {\n    margin: 0;\n    padding: 0;\n    background-color: var(--black);\n    height: 100vh;\n    width: 100vw;\n    font-family: 'Raleway';\n    letter-spacing: 0.3px;\n    overflow: hidden;\n}\n\n#menuRoot {\n    position: absolute;\n    top: 0;\n    left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
