import { useEffect, useState } from 'react';
import { ApiStatusType } from '../../app/api/apiStatusType';

export const useApiStatus = ({
    status,
    handleSuccess,
    handleFailed,
}: {
    status: ApiStatusType;
    handleSuccess: () => void;
    handleFailed?: () => void;
}) => {
    const [isPending, setIsPending] = useState(false);
    return useEffect(() => {
        if (status === ApiStatusType.IDLE && isPending) {
            handleSuccess();
        }

        if (status === ApiStatusType.FAILED) {
            handleFailed?.();
        }

        setIsPending(status === ApiStatusType.PENDING);
    }, [status]);
};
