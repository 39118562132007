// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dialog {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--dark-grey);
  gap: 16px;
  color: var(--white);
}
.custom-dialog .contact-title {
  font: var(--font-med-19) !important;
}
.custom-dialog .contact-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.custom-dialog .contact-info .contact-info-row {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  font: var(--font-reg-14) !important;
  color: var(--light-grey);
}`, "",{"version":3,"sources":["webpack://./src/features/interviewResult/components/InterviewContactDialog.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,kCAAA;EACA,SAAA;EACA,mBAAA;AACJ;AACI;EACI,mCAAA;AACR;AAEI;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAAR;AAEQ;EACI,aAAA;EACA,iBAAA;EACA,SAAA;EACA,mCAAA;EACA,wBAAA;AAAZ","sourcesContent":[".custom-dialog {\n    display: flex;\n    flex-direction: column;\n    padding: 16px;\n    border-radius: 8px;\n    background-color: var(--dark-grey);\n    gap: 16px;\n    color: var(--white);\n\n    .contact-title {\n        font: var(--font-med-19) !important;\n    }\n\n    .contact-info {\n        display: flex;\n        flex-direction: column;\n        gap: 8px;\n\n        .contact-info-row {\n            display: flex;\n            flex-wrap: nowrap;\n            gap: 16px;\n            font: var(--font-reg-14) !important;\n            color: var(--light-grey);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
