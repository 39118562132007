import { getLevelTitle } from '../../../app/availableLevelsConstant';
import { InterviewModel } from '../core/models';
import './InterviewsCatalogItem.scss';
import { useNavigate } from 'react-router-dom';

export type InterviewsCatalogItemProps = {
    interview: InterviewModel;
};

export const InterviewsCatalogItem: React.FC<InterviewsCatalogItemProps> = ({
    interview,
}) => {
    const navigate = useNavigate();

    const onClick = () => {
        navigate('/interview/result/' + interview.id);
    };

    return (
        <>
            <div
                className="interview-catalog-item"
                onClick={onClick}>
                <div className="interview-catalog-item-title">
                    {interview.user.username}
                </div>
                <div className="interview-catalog-item-subtitle">
                    {[
                        interview.specialization.profession_name,
                        interview.specialization.programming_language,
                        getLevelTitle(interview.difficulty),
                    ]
                        .filter((i) => !!i)
                        .join(', ')}
                </div>
                <div className="interview-catalog-item-score">
                    {interview.score}/100
                </div>
            </div>
        </>
    );
};
