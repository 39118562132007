// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-menu {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--dark-grey);
  width: 240px;
  border: var(--black) solid 1px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 16px;
}
.popup-menu > *:first-child {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.popup-menu > *:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/popupMenu/PopupMenu.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,kCAAA;EACA,YAAA;EACA,8BAAA;EACA,yCAAA;AACJ;AACI;EACI,4BAAA;EACA,2BAAA;AACR;AAEI;EACI,+BAAA;EACA,8BAAA;AAAR","sourcesContent":[".popup-menu {\n    display: flex;\n    flex-direction: column;\n    border-radius: 8px;\n    background-color: var(--dark-grey);\n    width: 240px;\n    border: var(--black) solid 1px;\n    box-shadow: rgba(0,0,0,.2) 0 4px 16px;\n\n    & > *:first-child {\n        border-top-right-radius: 8px;\n        border-top-left-radius: 8px;\n    }\n\n    & > *:last-child {\n        border-bottom-right-radius: 8px;\n        border-bottom-left-radius: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
