// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/auth-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-page {
  display: grid;
  grid-template: "image content" 100%;
  grid-template-columns: 1fr 400px;
  height: 100%;
}

.image-viewer {
  grid-area: image;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: content;
  background-color: var(--dark-grey);
  gap: 32px;
  padding-top: 64px;
  border-left: var(--black) solid 1px;
  box-sizing: border-box;
  padding: 64px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 400px;
}
.content .form-container {
  width: 100%;
}
@media screen and (max-width: 400px) {
  .content {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    height: 100vh;
    width: 100vw;
  }
}
@media screen and (max-height: 500px) {
  .content {
    padding-top: 16px;
    padding-bottom: 16px;
    height: 100vh;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/features/auth/AuthPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mCAAA;EACA,gCAAA;EACA,YAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mDAAA;EACA,4BAAA;EACA,kCAAA;EACA,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kCAAA;EACA,SAAA;EACA,iBAAA;EACA,mCAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AACJ;AAAI;EACI,WAAA;AAER;AAAI;EAjBJ;IAkBQ,kBAAA;IACA,mBAAA;IACA,iBAAA;IACA,oBAAA;IACA,aAAA;IACA,YAAA;EAGN;AACF;AAFI;EAzBJ;IA0BQ,iBAAA;IACA,oBAAA;IACA,aAAA;EAKN;AACF;;AAFA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,SAAA;AAKJ","sourcesContent":[".auth-page {\n    display: grid;\n    grid-template: 'image content' 100%;\n    grid-template-columns: 1fr 400px;\n    height: 100%;\n}\n\n.image-viewer {\n    grid-area: image;\n    background: url('../../../public/auth-bg.png');\n    background-repeat: no-repeat;\n    background-position: center center;\n    background-size: cover;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    grid-area: content;\n    background-color: var(--dark-grey);\n    gap: 32px;\n    padding-top: 64px;\n    border-left: var(--black) solid 1px;\n    box-sizing: border-box;\n    padding: 64px;\n    overflow-y: auto;\n    overflow-x: hidden;\n    width: 400px;\n    .form-container {\n        width: 100%;\n    }\n    @media screen and (max-width: 400px) {\n        padding-left: 16px;\n        padding-right: 16px;\n        padding-top: 16px;\n        padding-bottom: 16px;\n        height: 100vh;\n        width: 100vw;\n    }\n    @media screen and (max-height: 500px) {\n        padding-top: 16px;\n        padding-bottom: 16px;\n        height: 100vh;\n    }\n}\n\n.form {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
