// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interview-new-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-grey);
  border-radius: 8px;
  height: 120px;
  color: var(--light-grey);
  gap: 4px;
  cursor: pointer;
}
.interview-new-card:hover {
  background-color: var(--hover-grey);
}
.interview-new-card:hover .title,
.interview-new-card:hover .icon {
  color: var(--white) !important;
}
.interview-new-card .title {
  font: var(--font-reg-14);
}`, "",{"version":3,"sources":["webpack://./src/features/interviews/components/InterviewNewCard.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kCAAA;EACA,kBAAA;EACA,aAAA;EACA,wBAAA;EACA,QAAA;EACA,eAAA;AACJ;AACI;EACI,mCAAA;AACR;AAAQ;;EAEI,8BAAA;AAEZ;AAEI;EACI,wBAAA;AAAR","sourcesContent":[".interview-new-card {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: var(--dark-grey);\n    border-radius: 8px;\n    height: 120px;\n    color: var(--light-grey);\n    gap: 4px;\n    cursor: pointer;\n\n    &:hover {\n        background-color: var(--hover-grey);\n        .title,\n        .icon {\n            color: var(--white) !important;\n        }\n    }\n\n    .title {\n        font: var(--font-reg-14);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
