import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './InterviewContactDialog.scss';
import { getUserContacts } from '../core/api';

export interface InterviewContactDialogProps {
    userId: number;
    open: boolean;
    onClose: (value: string) => void;
}

export const InterviewContactDialog: React.FC<InterviewContactDialogProps> = ({
    open,
    userId,
    onClose,
}) => {
    const [userContacts, setUserContacts] = useState<{
        username: string;
        email: string;
        telegram?: string;
        phone?: string;
    } | null>(null);

    useEffect(() => {
        if (open) {
            getUserContacts({ userId }).then((i) => {
                setUserContacts(i.data as any);
            }).catch(() => {});
        }
    }, [open]);

    useEffect(() => {
        console.log(userContacts)
    }, [userContacts])

    return (
        <Dialog open={open}
        PaperProps={{style: {backgroundColor: 'transparent'}}} onClose={onClose}>
            <div className="custom-dialog">
                <div className="contact-title">
                    Контакты для связи с {userContacts?.username}
                </div>
                <div className="contact-info">
                    <div className="contact-info-row">
                        <div className="contact-info-title">Email: </div>
                        <div className="contact-info-value">
                            {userContacts?.email}
                        </div>
                    </div>
                    {userContacts?.telegram && (
                        <div className="contact-info-row">
                            <div className="contact-info-title">Телеграм: </div>
                            <div className="contact-info-value">
                                {userContacts?.telegram}
                            </div>
                        </div>
                    )}
                    {userContacts?.phone && (
                        <div className="contact-info-row">
                            <div className="contact-info-title">Телефон: </div>
                            <div className="contact-info-value">
                                {userContacts?.phone}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    );
};
