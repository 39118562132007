import { apiClient } from "../../../app/api/apiClient"
import { ApiPaginationType } from "../../../app/api/apiPaginationType";
import { InterviewModel, SpecializationModel } from "./models";
import { InterviewsFilters } from "./types";

export const getSpecializations = async () => {
        return await apiClient.get<SpecializationModel[]>(
            '/interview/specialization_list/'
        );
    };

export const getAllInterviews = async () => {
    return await apiClient.get<ApiPaginationType<InterviewModel>>(`/interview/all_interviews/`);
};

export const getInterviewsWithFilters = async (filters: InterviewsFilters) => {
    return await apiClient.get<ApiPaginationType<InterviewModel>>(
        `/interview/all_interviews/`,
        {
            params: filters
        }
    );
};