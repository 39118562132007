import { LoginData, SignUpData, UserModel } from './models';
import { apiClient } from '../../../app/api/apiClient';

export const authApi = {
    getUser: async () => {
        return await apiClient.get<UserModel>('/auth/get_user/');
    },
    login: async (data: LoginData) => {
        return await apiClient.post<UserModel>('/auth/login/', data);
    },
    signup: async (data: SignUpData) => {
        return await apiClient.post('/auth/signup/', data);
    },
    logout: async () => {
        return await apiClient.post('/auth/logout/');
    },
    getCSRFToken: async () => {
        const response = await apiClient.get('/auth/get_csrf/');
        const {csrfToken} = response.data;
        apiClient.defaults.headers.post['X-CSRFToken'] = csrfToken;
        apiClient.defaults.headers.post['Referer'] = 'https://digital-inc.ru';
        return csrfToken;
    }
};
