import { HTMLAttributes } from "react";
import './Alert.scss';

export type AlertOwnProps = {
    message: string;
    type: 'positive' | 'negative';
};

export type AlertProps = HTMLAttributes<EventTarget> & AlertOwnProps;

export const Alert: React.FC<AlertProps> = ({
    type,
    message,
    className,
    ...props
}) => {
    return (
        <div {...props} className={"alert alert-" + type + ' ' + className}>
            {message}
        </div>
    );
};