import React from 'react';
import { ClickAwayListener, Grow, Popper, PopperProps } from '@mui/material';
import './PopupMenu.scss';

type PopupOwnProps = {
    anchorEl: HTMLElement;
    onClose: () => void;
    children: JSX.Element;
    offsetTop?: number;
    offsetLeft?: number;
};
export type PopupMenuProps = PopperProps & PopupOwnProps;

export const PopupMenu: React.FC<PopupMenuProps> = ({
    offsetTop,
    offsetLeft,
    children,
    onClose,
    ...props
}) => {
    const handleClose = (event: Event) => {
        if (
            props.anchorEl &&
            props.anchorEl?.contains(event.target as HTMLElement)
        ) {
            return;
        }

        onClose();
    };

    return (
        <Popper role={undefined} placement="bottom-start" transition {...props}>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom-start'
                                ? 'left top'
                                : 'left bottom',
                        offset: '16,0',
                    }}>
                    <div>
                        <ClickAwayListener onClickAway={handleClose}>
                            <div
                                className="popup-menu"
                                style={{
                                    marginTop: offsetTop + 'px',
                                    marginLeft: offsetLeft + 'px',
                                }}>
                                {children}
                            </div>
                        </ClickAwayListener>
                    </div>
                </Grow>
            )}
        </Popper>
    );
};
