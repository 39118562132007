import { RootState } from '../../../app/store';

export const selectInterviewPreparationInterviewId = (state: RootState) => {
    return state.interviewPreparation.interviewId;
};
export const selectInterviewPreparationStep = (state: RootState) => {
    return state.interviewPreparation.steps[state.interviewPreparation.stepId];
};

export const selectInterviewPreparationStepId = (state: RootState) => {
    return state.interviewPreparation.stepId;
};
export const selectInterviewPreparationSteps = (state: RootState) => {
    return state.interviewPreparation.steps;
};
export const selectInterviewPreparationSpecialization = (
    state: RootState
) => {
    return state.interviewPreparation.specialization;
};
export const selectInterviewPreparationDifficulties = (state: RootState) => {
    return state.interviewPreparation.availableLevels;
};
export const selectInterviewPreparationDifficulty = (state: RootState) => {
    return state.interviewPreparation.difficulty;
};
export const selectInterviewPreparationSpecializations = (
    state: RootState
) => {
    const { specializationQuery: query } = state.interviewPreparation;
    return query ? state.interviewPreparation.availableSpecialization.filter((i) => {
        return (
            i.category_name?.startsWith(query) ||
            i.description?.startsWith(query) ||
            i.profession_name?.startsWith(query) ||
            i.programming_language?.name.startsWith(query)
        );
    }) : state.interviewPreparation.availableSpecialization;
};
