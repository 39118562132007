// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interview-catalog-item {
  display: grid;
  grid-template: "title score" "subtitle score";
  padding: 6px 16px;
  height: 44px;
  max-height: 44px;
  gap: 2px;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.interview-catalog-item-title {
  width: fit-content;
  height: fit-content;
  grid-area: title;
  color: var(--light-grey);
  font: var(--font-reg-14);
}

.interview-catalog-item-subtitle {
  width: fit-content;
  height: fit-content;
  grid-area: subtitle;
  color: var(--grey);
  font: var(--font-reg-12);
}

.interview-catalog-item-score {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-area: score;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--positive);
}

.interview-catalog-item:hover {
  background-color: var(--hover-grey);
}
.interview-catalog-item:hover .interview-catalog-item-title {
  color: var(--white);
}
.interview-catalog-item:hover .interview-catalog-item-subtitle {
  color: var(--light-grey);
}`, "",{"version":3,"sources":["webpack://./src/features/interviewsCatalog/components/InterviewsCatalogItem.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,6CAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,QAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,wBAAA;EACA,wBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,wBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;AACJ;;AAEA;EACI,mCAAA;AACJ;AAAI;EACI,mBAAA;AAER;AACI;EACI,wBAAA;AACR","sourcesContent":[".interview-catalog-item {\n    display: grid;\n    grid-template: 'title score' 'subtitle score';\n    padding: 6px 16px;\n    height: 44px;\n    max-height: 44px;\n    gap: 2px;\n    align-items: center;\n    box-sizing: border-box;\n    cursor: pointer;\n}\n\n.interview-catalog-item-title {\n    width: fit-content;\n    height: fit-content;\n    grid-area: title;\n    color: var(--light-grey);\n    font: var(--font-reg-14);\n}\n\n.interview-catalog-item-subtitle {\n    width: fit-content;\n    height: fit-content;\n    grid-area: subtitle;\n    color: var(--grey);\n    font: var(--font-reg-12);\n}\n\n.interview-catalog-item-score {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    grid-area: score;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    color: var(--positive);\n}\n\n.interview-catalog-item:hover {\n    background-color: var(--hover-grey);\n    .interview-catalog-item-title {\n        color: var(--white);\n    }\n\n    .interview-catalog-item-subtitle {\n        color: var(--light-grey);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
