// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 44px);
  overflow-y: auto;
  overflow-x: hidden;
}
.interviews .empty-status-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 88px 16px 0;
}
.interviews .header-title {
  color: var(--white);
  font: var(--font-med-14);
}
.interviews .container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  width: 100%;
  box-sizing: border-box;
  gap: 16px;
  padding: 0 16px 16px;
}
@media screen and (max-width: 370px) {
  .interviews .container-grid {
    grid-template-columns: repeat(1, 100%);
  }
}
.interviews .header-title-main {
  position: sticky;
  top: 0;
  background-color: var(--black);
  width: 100%;
  padding: 24px 16px 16px;
  color: var(--white);
  font: var(--font-med-19);
  align-self: start;
}`, "",{"version":3,"sources":["webpack://./src/features/interviews/InterviewsPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,0BAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,oBAAA;AACR;AAEI;EACI,mBAAA;EACA,wBAAA;AAAR;AAGI;EACI,aAAA;EACA,4DAAA;EACA,WAAA;EACA,sBAAA;EACA,SAAA;EACA,oBAAA;AADR;AAEQ;EAPJ;IAQQ,sCAAA;EACV;AACF;AAEI;EACI,gBAAA;EACA,MAAA;EACA,8BAAA;EACA,WAAA;EACA,uBAAA;EACA,mBAAA;EACA,wBAAA;EACA,iBAAA;AAAR","sourcesContent":[".interviews {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    height: calc(100vh - 44px);\n    overflow-y: auto;\n    overflow-x: hidden;\n\n    .empty-status-container {\n        display: flex;\n        flex-direction: column;\n        gap: 32px;\n        padding: 88px 16px 0;\n    }\n    \n    .header-title {\n        color: var(--white);\n        font: var(--font-med-14);\n    }\n    \n    .container-grid {\n        display: grid;\n        grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));\n        width: 100%;\n        box-sizing: border-box;\n        gap: 16px;\n        padding: 0 16px 16px;\n        @media screen and (max-width: 370px) {\n            grid-template-columns: repeat(1, 100%);\n        }\n    }\n    \n    .header-title-main {\n        position: sticky;\n        top: 0;\n        background-color: var(--black);\n        width: 100%;\n        padding: 24px 16px 16px;\n        color: var(--white);\n        font: var(--font-med-19);\n        align-self: start;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
