import { createAsyncThunk } from '@reduxjs/toolkit';
import { interviewApi } from './api';
import { SpecializationModel } from './models';
import { StartInterviewData } from './types';

export const getSpecializationsThunk = createAsyncThunk(
    'interview/getSpecializations',
    async () => {
        const res = await interviewApi.getSpecializations();
        return res.data;
    }
);

export const startInterviewThunk = createAsyncThunk(
    'interview/startInterview',
    async (data: StartInterviewData) => {
        const res = await interviewApi.startInterview(data);
        return res.data;
    }
);
