// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  display: grid;
  height: 100%;
  background-color: var(--black);
}

.app-unauthorized {
  grid-template-rows: 1fr;
}

.app-authorized {
  grid-template-rows: 45px 1fr;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,8BAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;EACI,4BAAA;AACJ","sourcesContent":[".app {\n    display: grid;\n    height: 100%;\n    background-color: var(--black);\n}\n\n.app-unauthorized {\n    grid-template-rows: 1fr;\n}\n\n.app-authorized {\n    grid-template-rows: 45px 1fr;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
