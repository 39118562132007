// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  border-radius: 8px;
  outline: none;
  border: none;
  height: 44px;
  font: var(--font-reg-14);
}

.button-default {
  background-color: var(--black);
  color: var(--light-grey);
}
.button-default:hover {
  color: var(--white);
}
.button-default:disabled {
  color: var(--grey);
}

.button-positive {
  background-color: var(--positive);
  color: var(--white);
}
.button-positive:hover {
  background-color: var(--positive-hover);
}
.button-positive:disabled {
  background-color: var(--positive-disabled);
  color: var(--light-grey);
}

.button-negative {
  background-color: var(--negative);
  color: var(--white);
}
.button-negative:hover {
  background-color: var(--negative-hover);
}
.button-negative:disabled {
  background-color: var(--negative-disabled);
  color: var(--light-grey);
}

.button-grey {
  background-color: var(--dark-grey);
  color: var(--light-grey);
}
.button-grey:hover {
  background-color: var(--hover-grey);
}
.button-grey:disabled {
  background-color: var(--dark-grey);
  color: var(--grey);
}`, "",{"version":3,"sources":["webpack://./src/shared/components/button/Button.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,QAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;EACA,wBAAA;AACJ;;AAEA;EACI,8BAAA;EACA,wBAAA;AACJ;AAAI;EACI,mBAAA;AAER;AAAI;EACI,kBAAA;AAER;;AAEA;EACI,iCAAA;EACA,mBAAA;AACJ;AAAI;EACI,uCAAA;AAER;AAAI;EACI,0CAAA;EACA,wBAAA;AAER;;AAEA;EACI,iCAAA;EACA,mBAAA;AACJ;AAAI;EACI,uCAAA;AAER;AAAI;EACI,0CAAA;EACA,wBAAA;AAER;;AAGA;EACI,kCAAA;EACA,wBAAA;AAAJ;AACI;EACI,mCAAA;AACR;AACI;EACI,kCAAA;EACA,kBAAA;AACR","sourcesContent":[".button {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    gap: 8px;\n    border-radius: 8px;\n    outline: none;\n    border: none;\n    height: 44px;\n    font: var(--font-reg-14);\n}\n\n.button-default {\n    background-color: var(--black);\n    color: var(--light-grey);\n    &:hover {\n        color: var(--white);\n    }\n    &:disabled {\n        color: var(--grey);\n    }\n}\n\n.button-positive {\n    background-color: var(--positive);\n    color: var(--white);\n    &:hover {\n        background-color: var(--positive-hover);\n    }\n    &:disabled {\n        background-color: var(--positive-disabled);\n        color: var(--light-grey);\n    }\n}\n\n.button-negative {\n    background-color: var(--negative);\n    color: var(--white);\n    &:hover {\n        background-color: var(--negative-hover);\n    }\n    &:disabled {\n        background-color: var(--negative-disabled);\n        color: var(--light-grey);\n    }\n}\n\n\n.button-grey {\n    background-color: var(--dark-grey);\n    color: var(--light-grey);\n    &:hover {\n        background-color: var(--hover-grey);\n    }\n    &:disabled {\n        background-color: var(--dark-grey);\n        color: var(--grey);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
