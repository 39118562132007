import { createAsyncThunk } from "@reduxjs/toolkit";
import { interviewResultApi } from "./api";
import { InterviewResultModel } from "./models";

export const getInterviewResultThunk = createAsyncThunk<
    InterviewResultModel,
    number,
    { rejectValue: number }
>('interviewResult/getResult', async (interviewId, thunkAPI) => {
    try {
        const res = await interviewResultApi.getResult(interviewId);
        return res.data; 
    } catch (error) {
        const code = (<any>error).status;
        return thunkAPI.rejectWithValue(Number(code));
    }
});