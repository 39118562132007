import { RootState } from '../../../app/store';

export const selectInterviewAnswerId = (state: RootState) => {
    return state.interview.currentAnswerId;
};
export const selectInterviewQuestionURL = (state: RootState) => {
    return state.interview.currentQuestionURL;
};
export const selectInterviewStatus = (state: RootState) => {
    return state.interview.status;
};
