// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border: var(--black) solid 1px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 16px;
  min-width: 300px;
  color: var(--white);
  font: var(--font-reg-14);
  height: 44px;
  border-radius: 8px;
}

.alert-positive {
  background-color: var(--positive);
}

.alert-negative {
  background-color: var(--negative);
}`, "",{"version":3,"sources":["webpack://./src/shared/components/alert/Alert.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,8BAAA;EACA,yCAAA;EACA,gBAAA;EACA,mBAAA;EACA,wBAAA;EACA,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,iCAAA;AACJ;;AAEA;EACI,iCAAA;AACJ","sourcesContent":[".alert {\n    position: fixed;\n    bottom: 16px;\n    right: 16px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    padding: 0 16px;\n    border: var(--black) solid 1px;\n    box-shadow: rgba(0,0,0,.2) 0 4px 16px;\n    min-width: 300px;\n    color: var(--white);\n    font: var(--font-reg-14);\n    height: 44px;\n    border-radius: 8px;\n}\n\n.alert-positive {\n    background-color: var(--positive);\n}\n\n.alert-negative {\n    background-color: var(--negative);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
