import { useNavigate } from 'react-router-dom';
import { Button } from '../../shared/components/button/Button';
import { Input } from '../../shared/components/input/Input';
import './AuthPage.scss';
import { useForm } from 'react-hook-form';
import { LoginData } from './core/models';
import { loginThunk } from './core/thunks';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useState } from 'react';
import { selectCurrentAuthStatus } from './core/selectors';
import { Alert } from '../../shared/components/alert/Alert';
import { useApiStatus } from '../../shared/hooks/useApiStatus';

export const Login = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const status = useAppSelector(selectCurrentAuthStatus);

    const { register, handleSubmit } = useForm<LoginData>();
    const [needToShowAlert, setNeedToShowAlert] = useState<boolean>(false);

    const onSubmit = (data: LoginData) => {
        dispatch(loginThunk(data));
    };
    
    useApiStatus({
        status,
        handleSuccess: () => {
            navigate('/interviews/')
        },
        handleFailed: () => {
            setNeedToShowAlert(true);
            setTimeout(() => setNeedToShowAlert(false), 5000);
        },
    });

    return (
        <>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Input
                    placeholder="Ник или email"
                    name="username_or_email"
                    register={register}
                />
                <Input
                    type="password"
                    placeholder="Пароль"
                    name="password"
                    register={register}
                />
                <Button type="submit" styleType="positive">
                    Войти
                </Button>
                <Button
                    type="button"
                    styleType="default"
                    onClick={() => navigate('/auth/signup')}>
                    Вернуться к регистрации
                </Button>
            </form>
            {needToShowAlert && (
                <Alert type="negative" message="Неправильные данные..." />
            )}
        </>
    );
};
