import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getInterviewResultThunk } from './core/thunks';
import {
    selectInterviewResult,
    selectInterviewResultStatus,
    selectIsInterviewResultEmpty,
} from './core/selectors';
import { Accordion } from '../../shared/components/accordion/Accordion';
import './InterviewResultPage.scss';
import { ReactComponent as EmptyResults } from '../../shared/components/icons/EmptyResults.svg';
import { clearStateAction } from './core/slice';
import { ReactComponent as InfoIcon } from '../../shared/components/icons/Info.svg';
import { InterviewContactDialog } from './components/InterviewContactDialog';
import { ApiStatusType } from '../../app/api/apiStatusType';

export const InterviewResultPage = () => {
    const { id: interviewId } = useParams<{ id: string }>();

    const dispatch = useAppDispatch();

    const [isContactsOpen, setIsContactsOpen] = useState(false);

    const result = useAppSelector(selectInterviewResult);
    const status = useAppSelector(selectInterviewResultStatus);
    const isResultEmpty = useAppSelector(selectIsInterviewResultEmpty);
    const [resultCheckInterval, setResultCheckInterval] =
        useState<NodeJS.Timer | null>(null);

    useEffect(() => {
        if (interviewId === null) {
            return;
        }

        dispatch(clearStateAction);
        dispatch(getInterviewResultThunk(Number(interviewId)));
    }, []);

    useEffect(() => {
        if (resultCheckInterval !== null && !isResultEmpty) {
            clearInterval(resultCheckInterval);
        }

        if (interviewId === null) {
            return;
        }

        if (!isResultEmpty) {
            return;
        }

        setResultCheckInterval(
            setInterval(() => {
                dispatch(getInterviewResultThunk(Number(interviewId)));
            }, 10000)
        );
    }, [isResultEmpty]);

    useEffect(() => () => {
        if (resultCheckInterval !== null) {
            clearInterval(resultCheckInterval);
        }
    });

    if (status === ApiStatusType.PENDING) {
        return <>Loading...</>;
    }

    if (isResultEmpty) {
        return (
            <div className="interview-result">
                <div className="interview-result-empty">
                    <EmptyResults />
                    <div className="interview-result-placeholder">
                        Ваше собеседование находится в обработке и скоро тут
                        появятся результаты
                    </div>
                </div>
            </div>
        );
    }

    if (!result) {
        return <>Loading...</>;
    }

    return (
        <>
            <div className="interview-result">
                <div className="interview-result-sticky-wrapper">
                    <div className="interview-result-title">
                        {String(result.profession)} - {String(result.category)}
                    </div>
                    <div className="interview-result-subtitle">
                        Пройденно {String(result.user.username)}
                        <span
                            className="interview-result-subtitle-span"
                            onClick={() => setIsContactsOpen(true)}>
                            <InfoIcon />
                        </span>
                    </div>
                </div>
                <div className="interview-result-answers">
                    {result.answers?.map((i) => (
                        <Accordion
                            key={i.question}
                            accordionTitle={i.question}
                            score={String(i.score)}>
                            <div className="result-answer-t">
                                Ответ: {i.correct_answer}
                            </div>
                            <div className="result-answer-t">
                                Ваш ответ: {i.answer}
                            </div>
                        </Accordion>
                    ))}
                </div>
            </div>
            <InterviewContactDialog
                open={isContactsOpen}
                onClose={() => setIsContactsOpen(false)}
                userId={result.user.id}
            />
        </>
    );
};
