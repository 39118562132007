import './InterviewsCatalogPage.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectFilters,
    selectInterviews,
    selectSpecializations,
} from './core/selectors';
import { InterviewsCatalogItem } from './components/InterviewsCatalogItem';
import { useEffect } from 'react';
import { getAllInterviewsThunk, getInterviewsWithFiltersThunk, getSpecializationsThunk } from './core/thunks';
import { SelectInput } from '../../shared/components/selectInput/SelectInput';
import { Input } from '../../shared/components/input/Input';
import {
    clearFiltersAction,
    setDifficultyFilterAction,
    setScoreMinFilterAction,
    setSpecializationFilterAction,
} from './core/slice';
import {
    getAllLevelsTitles,
    getLevelNumber,
} from '../../app/availableLevelsConstant';

export const InterviewsCatalogPage = () => {
    const dispatch = useAppDispatch();

    const interviews = useAppSelector(selectInterviews);
    const filters = useAppSelector(selectFilters);
    const specializations = useAppSelector(selectSpecializations);

    useEffect(() => {
        dispatch(getAllInterviewsThunk());
        if (!specializations.length) {
            dispatch(getSpecializationsThunk());
        }
    }, []);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            dispatch(getInterviewsWithFiltersThunk(filters));
        } else {
            dispatch(getAllInterviewsThunk());
        }
    }, [filters])

    useEffect(() => () => {
        dispatch(clearFiltersAction({}));
    }, [])

    return (
        <div className="interviews-catalog-outside-wrapper">
            <div className='header-title-main'>Все собеседования</div>
            <div className="interviews-catalog-wrapper">
                <div className="interviews-catalog-filters">
                    <SelectInput
                        options={specializations.map((i) => i.profession_name)}
                        placeholder="Профессия"
                        onSelectOption={(i) => {
                            dispatch(
                                setSpecializationFilterAction(
                                    specializations.find(
                                        (s) => s.profession_name === i
                                    )?.id
                                )
                            );
                        }}
                    />
                    <SelectInput
                        options={getAllLevelsTitles()}
                        placeholder="Уровень"
                        onSelectOption={(i) => {
                            dispatch(
                                setDifficultyFilterAction(getLevelNumber(i))
                            );
                        }}
                    />
                    <Input
                        placeholder="Минимальный балл"
                        onBlur={({ target }) => {
                            dispatch(
                                setScoreMinFilterAction(
                                    Number((target as HTMLInputElement).value)
                                )
                            );
                        }}
                        onKeyDown={({ target, key }) => {
                            if (key !== 'Enter') {
                                return;
                            }
                            (target as HTMLInputElement).blur();
                        }}
                    />
                </div>
                {interviews.map((i) => (
                    <InterviewsCatalogItem key={i.id} interview={i} />
                ))}
            </div>
        </div>
    );
};
